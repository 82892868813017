export const auth0 = {
  apiId: '61c3fecd47d134003e7e37df',
  /**
   * This is also treated as the API's identifier
   */
  audience: 'https://dev-ii1djocg.us.auth0.com/api/v2/',
  domain: 'dev-ii1djocg.us.auth0.com',
  clientId: 'XdBPzoOOaZWSAvOAz7V08PdTe7slc9tR',
  redirectUri:
    typeof window !== 'undefined'
      ? `${window.location.origin}/admin`
      : '/admin',
}
