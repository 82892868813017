import React from 'react'
import type { IconButtonProps, ButtonGroupProps } from '@chakra-ui/react'
import { ButtonGroup, Box, Link, IconButton, Image } from '@chakra-ui/react'
import facebook from 'resources/images/facebook.png'
import medium from 'resources/images/medium.png'
import dev from 'resources/images/dev.png'
import github from 'resources/images/github.png'
import linkedin from 'resources/images/linkedin.png'
import twitter from 'resources/images/twitter.png'

export interface SocialMediaButtonProps extends IconButtonProps {
  alt?: string
  height?: number | string
  title?: string
  url?: string
  name:
    | 'dev.to'
    | 'facebook'
    | 'github'
    | 'instagram'
    | 'jsmanifest'
    | 'linked.in'
    | 'medium'
    | 'twitter'
}

function getSocialMediaImage(name: SocialMediaButtonProps['name']) {
  switch (name) {
    case 'dev.to':
      return dev
    case 'facebook':
      return facebook
    case 'github':
      return github
    case 'linked.in':
      return linkedin
    case 'medium':
      return medium
    case 'twitter':
      return twitter
    case 'instagram':
    case 'jsmanifest':
    default:
      return null
  }
}

function SocialMediaButton({
  name,
  alt = name,
  height = '100%',
  size = 'md',
  url,
  ...rest
}: React.PropsWithChildren<SocialMediaButtonProps>) {
  const icon = (
    <Image alt={alt} src={getSocialMediaImage(name)} height={height} />
  )
  return (
    // @ts-expect-error
    <Box
      as={IconButton}
      aria-label={name}
      size={size}
      rounded="full"
      {...rest}
      _hover={{ bg: '#fff', opacity: 0.8 }}
    >
      {url
        ? React.cloneElement(
            <Link height={height} href={url} target="_blank">
              {icon}
            </Link>,
          )
        : icon}
    </Box>
  )
}

SocialMediaButton.Group = function SocialMediaButtonGroup({
  children,
  size,
  spacing,
  ...rest
}: ButtonGroupProps) {
  return (
    <ButtonGroup spacing={spacing} {...rest}>
      {React.Children.toArray(children).map((child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, { ...child?.props, size })
          : child,
      )}
    </ButtonGroup>
  )
}

export default SocialMediaButton
