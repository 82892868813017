import React from 'react'
import { css } from '@emotion/react'
import { Heading, Link, Text } from '@chakra-ui/react'

export interface ProjectCardProps {
  link: string
  title: string
  children: React.ReactNode
  bg: string
}

function ProjectCard({
  link,
  title,
  children,
  bg,
}: React.PropsWithChildren<ProjectCardProps>) {
  return (
    <Link
      href={link}
      target="_blank"
      rel="noreferrer noopener"
      background={bg || 'none'}
      boxShadow="0px 4px 4px #111"
      borderRadius={8}
      color="white"
      my={2}
      position="relative"
      pt={4}
      pb={5}
      px={4}
      minH={150}
      textDecoration="none"
      transition="all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275)"
      css={css({
        ':hover': {
          color: 'white',
          transform: 'translateY(-5px)',
          boxShadow: '0px 4px 4px #111',
        },
      })}
    >
      <Text opacity={0.8} textShadow="0 2px 10px rgba(0, 0, 0, 0.3)">
        {children}
      </Text>
      <Heading
        fontWeight={500}
        letterSpacing="wide"
        lineHeight={1}
        pt={4}
        size="md"
      >
        {title}
      </Heading>
    </Link>
  )
}

export default ProjectCard
