import React from 'react'
import { Heading, List, ListItem, Text } from '@chakra-ui/react'
import type { BoxProps } from 'components/Box'
import Box from 'components/Box'

export interface ExperienceCardProps extends BoxProps {
  descriptions?: string[]
  from?: string
  to?: string
}

function ExperienceCard({
  children,
  title,
  descriptions,
  from,
  to,
  ...props
}: React.PropsWithChildren<ExperienceCardProps>) {
  return (
    <Box mode="card.dark" p={3} {...props}>
      <Heading>{title}</Heading>
      <Box>
        <Text>{from}</Text> <Text>{to || '-'}</Text>
      </Box>
      <List spacing={2}>
        {descriptions.map((description) => (
          <ListItem key={description}>{children}</ListItem>
        ))}
      </List>
    </Box>
  )
}

export default ExperienceCard
