/**
 * Chakra theme
 * 		specification: https://system-ui.com/theme/
 * 		source: https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme
 * 		files: https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/foundations
 *
 * Theme color
 * 		Link: https://colorhunt.co/palette/283149404b6900818adbedf3
 *
 * Postinstall generates typings to:
 * 		node_modules/@chakra-ui/styled-system/dist/declarations/src/theming.types.d.ts
 */
const { css, extendTheme, theme: chakraTheme } = require('@chakra-ui/react')

const theme = extendTheme({
  ...chakraTheme,
  colors: {
    blue: {
      50: '#f6fafd',
      100: '#e4eff9',
      200: '#d0e3f5',
      300: '#bbd6f0',
      400: '#a3c8eb',
      500: '#88b8e5',
      600: '#68a5de',
      700: '#418dd5',
      800: '#326fa9',
      900: '#1d4163',
    },
    cyan: {
      50: '#f0fcfc',
      100: '#d0f5f4',
      200: '#abedec',
      300: '#7fe3e2',
      400: '#49d7d5',
      500: '#3ac5c4',
      600: '#34b0af',
      700: '#2d9796',
      800: '#237876',
      900: '#154646',
    },
    gray: {
      50: '#faf9fa',
      100: '#f1ecee',
      200: '#e6dfe2',
      300: '#dbd0d5',
      400: '#cec0c7',
      500: '#c0aeb7',
      600: '#b19aa5',
      700: '#9e818f',
      800: '#7e6572',
      900: '#4a3b42',
    },
    fuchsia: {
      50: '#fdf8fd',
      100: '#fae8fa',
      200: '#f6d7f6',
      300: '#f2c5f2',
      400: '#edb0ee',
      500: '#e797e8',
      600: '#e079e2',
      700: '#d74fd9',
      800: '#b035b2',
      900: '#691f6a',
    },
    green: {
      50: '#f2fcf2',
      100: '#d6f6d5',
      200: '#b6efb5',
      300: '#91e790',
      400: '#64dc61',
      500: '#3fcd3d',
      600: '#39b736',
      700: '#319d2e',
      800: '#267c25',
      900: '#174916',
    },
    indigo: {
      50: '#f9f9fe',
      100: '#ececfb',
      200: '#dedff8',
      300: '#cfd0f5',
      400: '#bfc0f1',
      500: '#acaeed',
      600: '#9798e8',
      700: '#7d7fe3',
      800: '#5a5cdb',
      900: '#2e309a',
    },
    lime: {
      50: '#f5fbef',
      100: '#e1f4cc',
      200: '#caeba6',
      300: '#b0e27a',
      400: '#92d747',
      500: '#82c53a',
      600: '#74b034',
      700: '#64972d',
      800: '#4f7723',
      900: '#2e4615',
    },
    orange: {
      50: '#fdf9f5',
      100: '#f8ebdf',
      200: '#f3ddc8',
      300: '#edcdaf',
      400: '#e7bc93',
      500: '#e0a872',
      600: '#d8904c',
      700: '#be7938',
      800: '#96602c',
      900: '#58381a',
    },
    pink: {
      50: '#fdf8fb',
      100: '#fae9f2',
      200: '#f7d9e8',
      300: '#f3c7de',
      400: '#eeb3d2',
      500: '#e99cc4',
      600: '#e380b3',
      700: '#db5b9e',
      800: '#bc387d',
      900: '#70214a',
    },
    red: {
      50: '#fef8f8',
      100: '#faeaea',
      200: '#f7dada',
      300: '#f3c9c9',
      400: '#efb6b6',
      500: '#ea9fa0',
      600: '#e48586',
      700: '#dd6264',
      800: '#c43a3c',
      900: '#742223',
    },
    teal: {
      50: '#f1fcf6',
      100: '#d3f5e4',
      200: '#b1eecf',
      300: '#8ae5b6',
      400: '#5adb98',
      500: '#3cca81',
      600: '#35b573',
      700: '#2e9b63',
      800: '#247b4e',
      900: '#15482e',
    },
    violet: {
      50: '#fbf8fe',
      100: '#f2ebfb',
      200: '#e9dcf7',
      300: '#dfcbf4',
      400: '#d3b9f0',
      500: '#c7a5eb',
      600: '#b88de6',
      700: '#a670e0',
      800: '#8c47d7',
      900: '#542784',
    },
    yellow: {
      50: '#fbfaeb',
      100: '#f1f0bf',
      200: '#e6e58e',
      300: '#dad857',
      400: '#cbc93c',
      500: '#bab837',
      600: '#a6a431',
      700: '#8e8d2a',
      800: '#706f21',
      900: '#424113',
    },
  },
  config: { ...chakraTheme.config, initialColorMode: 'dark' },
  // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/foundations/colors.ts
  // colors: {
  // primary: '#473B7B',
  // secondary: '#2c323f',
  // background: {
  //   default: '#2c323f',
  //   gradient: `linear-gradient(-225deg, #473B7B 0%, #3584A7 51%, #30D2BE 100%)`,
  //   paper: '#473B7B',
  // },
  // divider: '#30D2BE',
  // },
  fonts: {
    body: 'Raleway, sans-serif',
    heading: 'Open Sans, serif',
    mono: 'Consolas, monospace',
  },
  // fontSizes: {
  //   xs: '0.75rem',
  //   sm: '0.875rem',
  //   md: '1rem',
  //   lg: '1.125rem',
  //   xl: '1.25rem',
  //   '2xl': '1.5rem',
  //   '3xl': '1.875rem',
  //   '4xl': '2.25rem',
  //   '5xl': '3rem',
  //   '6xl': '3.75rem',
  //   '7xl': '4.5rem',
  //   '8xl': '6rem',
  //   '9xl': '8rem',
  // },
  // fontWeights: {
  //   hairline: 100,
  //   thin: 200,
  //   light: 300,
  //   normal: 400,
  //   medium: 500,
  //   semibold: 600,
  //   bold: 700,
  //   extrabold: 800,
  //   black: 900,
  // },
  // lineHeights: {
  //   normal: 'normal',
  //   none: 1,
  //   shorter: 1.25,
  //   short: 1.375,
  //   base: 1.5,
  //   tall: 1.625,
  //   taller: '2',
  //   '3': '.75rem',
  //   '4': '1rem',
  //   '5': '1.25rem',
  //   '6': '1.5rem',
  //   '7': '1.75rem',
  //   '8': '2rem',
  //   '9': '2.25rem',
  //   '10': '2.5rem',
  // },
  // letterSpacings: {
  //   tighter: '-0.05em',
  //   tight: '-0.025em',
  //   normal: '0',
  //   wide: '0.025em',
  //   wider: '0.05em',
  //   widest: '0.1em',
  // },
  // components: {
  // Button: {
  //   baseStyle: {
  //     fontWeight: 'bold',
  //   },
  //   sizes: {
  //     xl: {
  //       h: '56px',
  //       fontSize: 'lg',
  //       px: '32px',
  //     },
  //   },
  //   variants: {
  //     'with-shadow': {
  //       bg: 'red.400',
  //       boxShadow: '0 0 2px 2px #efdfde',
  //     },
  //     // 4. We can override existing variants
  //     solid: (props) => ({
  //       bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
  //     }),
  //   },
  // },
  // },
  styles: {
    global: {
      body: css({
        backgroundColor: 'blackAlpha.800',
      }),
    },
  },
})

module.exports = theme
