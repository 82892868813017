import React from 'react'
import type { BoxProps as ChakraBoxProps } from '@chakra-ui/react'
import { Box as ChakraBox } from '@chakra-ui/react'

export type BoxMode = 'card.dark' | 'card' | 'default' | 'modal'

export interface BoxProps extends ChakraBoxProps {
  mode?: BoxMode
  [key: string]: any
}

function getModeProps(mode: BoxMode): Partial<ChakraBoxProps> {
  switch (mode) {
    case 'card':
    case 'card.dark':
      return {
        bg: mode === 'card.dark' ? 'blackAlpha.400' : 'white',
        borderRadius: 'lg',
        py: 3,
      }
    case 'modal':
      return {
        bg: 'twitter.900',
        border: '4px solid cyan',
        borderRadius: 10,
        p: 10,
        textAlign: 'center',
      }
    case 'default':
      return undefined
  }
}

const Box: React.FC<BoxProps> = React.forwardRef<BoxProps>(
  ({ children, mode = 'default', ...rest }: BoxProps, ref) => {
    return (
      <ChakraBox ref={ref as any} {...rest} {...getModeProps(mode)}>
        {children}
      </ChakraBox>
    )
  },
)

export default Box
