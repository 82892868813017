import React from 'react'
import { Helmet } from 'react-helmet'
import { MDXProvider } from '@mdx-js/react'
import { RecoilRoot } from 'recoil'
import {
  Box,
  ButtonGroup,
  CSSReset,
  Code,
  Divider,
  Heading,
  Image,
  Link,
  List,
  ListItem,
  OrderedList,
  Table,
  Text,
} from '@chakra-ui/react'
import { Auth0Provider } from '@auth0/auth0-react'
import ProjectCard from './src/components/ProjectCard'
import ExperienceCard from './src/components/ExperienceCard'
import SocialMediaButton from './src/components/SocialMediaButton'
import { auth0 as auth0Config } from './src/app/config'
// import favicon from './src/resources/favicon.png'

const withProps = (Component, overrideProps) => (props) =>
  <Component {...props} {...overrideProps} />

/** @type { MDXProviderComponents } */
const components = {
  Box,
  ButtonGroup,
  ExperienceCard,
  ProjectCard,
  SocialMediaButton,
  SocialMediaButtonGroup: SocialMediaButton.Group,
  a: Link,
  code: Code,
  h1: withProps(Heading, { as: 'h1', size: '4xl' }),
  h2: withProps(Heading, { as: 'h2', size: '3xl' }),
  h3: withProps(Heading, { as: 'h3', size: 'xl' }),
  h4: withProps(Heading, { as: 'h4', size: 'lg' }),
  h5: withProps(Heading, { as: 'h5', size: 'md' }),
  h6: withProps(Heading, { as: 'h6', size: 'sm' }),
  hr: Divider,
  img: Image,
  p: withProps(Text, { fontSize: 'lg' }),
  pre: Code,
  ul: List,
  li: ListItem,
  ol: OrderedList,
  span: Text,
  table: Table,
}

export default ({ element, headComponents }) => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <meta charSet="utf-8" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge; charset=UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {headComponents}
        <meta name="robots" content="index,follow"></meta>
        {/* <link rel="icon" type="image/png" href={favicon} sizes="32x32" /> */}
      </Helmet>
      <Auth0Provider
        clientId={auth0Config.clientId}
        domain={auth0Config.domain}
        redirectUri={auth0Config.redirectUri}
        useRefreshTokens
      >
        <RecoilRoot>
          <MDXProvider components={components}>
            <CSSReset />
            {element}
          </MDXProvider>
        </RecoilRoot>
      </Auth0Provider>
    </>
  )
}
